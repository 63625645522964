  <template>
  <div class="text-center">
    <div class="mb-4 mt-2">Your link has expired.</div>
    <div class="mb-4 mt-2">For security purposes, links are only valid for a short amount of time.</div>
    <div class="mb-4 mt-2">Continue to Stripe using a new link, by clicking the button below:</div>
    <div class="mt-6" v-if="mounted">
      <v-btn :disabled="!data.url" color="primary" :href="data.url" target="_blank">
        <v-icon class="mr-2" color="white">mdi-lock</v-icon>Continue to Stripe
      </v-btn>
    </div>
    <!--div class="subtitle-2 pt-6">If you have any issues don't hesitate to get in touch</div>
    <vx-link class="subtitle-2" support /-->
  </div>
</template>
<script>
import StripeMixin from '@mixins/stripe.mixin';
export default {
  name: 'StripeRefresh',
  mixins: [StripeMixin],
};
</script>
